<template>
	<section>
		<div class="appt-form">
			<div class="appt-form__heading">
				<!-- Admin View -->
				<template v-if="isAdmin">
					<!-- Update View -->
					<template v-if="isUpdate">
						<div class="flex-between">
							<h5>{{ $t("Appointment.AppointmentId", { id: appointmentForm.code }) }}</h5>
						</div>
					</template>

					<!-- Add View -->
					<template v-else>
						<div class="flex-between">
							<h5>{{ $t("Appointment.CreateAppointment") }}</h5>
						</div>
					</template>
				</template>

				<!-- Customer View -->
				<template v-else>
					<!-- Update View -->
					<template v-if="isUpdate">
						<div class="flex-between">
							<h1>{{ $t("Appointment.UpdateAppointment") }}</h1>
							<router-link
								:to="{
									name: 'CustomerSearchForm',
								}"
								class="align-self-center"
							>
								{{ $t("Appointment.FindSchedule") }}
							</router-link>
						</div>
					</template>

					<!-- Create View -->
					<template v-else>
						<div class="flex-between">
							<h1>{{ $t("Appointment.Register") }}</h1>
							<router-link
								:to="{
									name: 'CustomerSearchForm',
								}"
								class="align-self-center"
							>
								{{ $t("Appointment.FindSchedule") }}
							</router-link>
						</div>
						<!-- eslint-disable vue/no-v-html -->
						<p v-html="$t('Appointment.Description')"></p>
						<!-- eslint-enable -->
					</template>
				</template>
			</div>

			<hr v-if="!isAdmin" class="h-divider" />

			<ValidationObserver ref="form">
				<form @submit.prevent="applyForm">
					<div class="appt-form__container">
						<!-- Appointment code -->
						<!-- Update View -->
						<div v-if="isUpdate" class="form-group">
							<label class="form-group__label">{{ $t("AppointmentSearch.ApptCode") }}</label>
							<div class="form-group__input">{{ appointmentForm.code }}</div>
						</div>

						<!-- Status -->
						<!-- Admin View or Customer Update View -->
						<div v-if="isAdmin || isUpdate" class="form-group required status">
							<label for="status" class="form-group__label">{{
								$t("AppointmentForm.Status")
							}}</label>
							<div v-if="isAdmin" class="form-group__input">
								<ValidationProvider
									v-slot="{ classes, errors }"
									ref="status"
									name="appointment_status"
									rules="required"
								>
									<div :class="[classes]">
										<multiselect
											id="status"
											v-model.trim="appointmentForm.status"
											placeholder=""
											:custom-label="labelStatus"
											:options="status"
											:close-on-select="true"
											:show-labels="false"
											:allow-empty="false"
											:searchable="false"
											@close="checkValidate('status')"
											@input="selectStatus"
										>
											<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
											<template #noResult>{{ $t("DropDown.NoResult") }}</template>
										</multiselect>
										<div class="error-mess">{{ errors[0] }}</div>
									</div>
								</ValidationProvider>
							</div>
							<div v-else-if="isUpdate" class="form-group__input font-weight-bold">{{
								computedStatus
							}}</div>
						</div>

						<!-- Sub field of the finished status in Admin view -->
						<template v-if="isAdmin && isStatusFinished">
							<div class="sub-form-group mb-2 required">
								<label for="result-date" class="sub-form-group__label">{{
									$t("AppointmentForm.ResultDate")
								}}</label>
								<div class="sub-form-group__input">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="appointment_result_date"
										rules="required"
									>
										<div :class="[classes]">
											<date-picker
												id="result-date"
												v-model.trim="appointmentForm.finishedDate"
												type="datetime"
												value-type="format"
												:format="clientDateFormat"
												:editable="false"
												:show-second="false"
												:show-time-panel="panelFinishedDate"
												class="w-100"
												@close="handleOpenChange('panelFinishedDate')"
												><template #footer>
													<button
														class="mx-btn mx-btn-text"
														@click="toggleTimePanel('panelFinishedDate')"
													>
														{{
															panelFinishedDate
																? $t("DatePicker.SelectDate")
																: $t("DatePicker.SelectTime")
														}}
													</button>
												</template></date-picker
											>
											<div class="error-mess">{{ errors[0] }}</div>
										</div>
									</ValidationProvider>
								</div>
							</div>

							<div class="sub-form-group mb-2">
								<label for="next-schedule" class="sub-form-group__label">{{
									$t("AppointmentForm.NextSchedule")
								}}</label>
								<div class="sub-form-group__input">
									<ValidationProvider v-slot="{ classes, errors }" name="appointment_next_schedule">
										<div :class="[classes]">
											<date-picker
												id="next-schedule"
												v-model.trim="appointmentForm.nextExamDate"
												type="datetime"
												value-type="format"
												:format="clientDateFormat"
												:editable="false"
												:show-second="false"
												:show-time-panel="panelExamDate"
												class="w-100"
												@close="handleOpenChange('panelExamDate')"
												><template #footer>
													<button
														class="mx-btn mx-btn-text"
														@click="toggleTimePanel('panelExamDate')"
													>
														{{
															panelExamDate
																? $t("DatePicker.SelectDate")
																: $t("DatePicker.SelectTime")
														}}
													</button>
												</template></date-picker
											>
											<div class="error-mess">{{ errors[0] }}</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</template>

						<!-- Find customers -->
						<div v-if="isAdmin && !isUpdate" class="form-group find-customer">
							<label for="find-customer" class="form-group__label">{{
								$t("AppointmentForm.FindCustomers")
							}}</label>
							<div class="form-group__input">
								<ValidationProvider
									v-slot="{ classes, errors }"
									ref="find_customer"
									name="appointment_find_customer"
								>
									<div :class="[classes]">
										<multiselect
											id="find-customer"
											v-model.trim="findCustomer"
											track-by="id"
											label="fullName"
											:options="customers"
											:custom-label="nameWithPhone"
											:close-on-select="true"
											:show-labels="false"
											:allow-empty="true"
											:searchable="true"
											:internal-search="false"
											:options-limit="Number(totalCustomer)"
											:placeholder="$t('AppointmentSearch.SearchBy')"
											@open="getCustomers()"
											@search-change="asyncFindCustomers"
											@close="resetCustomers"
										>
											<template slot="clear" slot-scope="props">
												<div
													v-show="findCustomer"
													class="multiselect__clear"
													@mousedown.prevent.stop="clearFindCustomer(props.search)"
												></div>
											</template>
											<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
											<template #noResult>{{ $t("DropDown.NoResult") }}</template>
											<template v-if="isAppendLoading(customers)" #afterList>
												<infinite-loading
													:identifier="infiniteCustomerId"
													@infinite="infiniteHandlerCustomer"
												>
													<div slot="no-more"></div>
													<div slot="no-results"></div>
												</infinite-loading>
											</template>
										</multiselect>

										<div class="error-mess">{{ errors[0] }}</div>
									</div>
								</ValidationProvider>
							</div>
						</div>

						<hr v-if="isAdmin" class="h-divider mt-0" />

						<!-- Customer name -->
						<div class="form-group required">
							<label for="name" class="form-group__label">{{ $t("AppointmentForm.Name") }}</label>
							<div class="form-group__input">
								<!-- Create View -->
								<ValidationProvider
									v-if="!isUpdate"
									v-slot="{ classes, errors }"
									ref="customer_name"
									name="appointment_name"
									rules="required"
								>
									<div :class="[classes]">
										<input
											id="name"
											v-model.trim="appointmentForm.fullName"
											type="text"
											class="form-control"
											:disabled="isDisableName"
										/>
										<div class="error-mess">{{ errors[0] }}</div>
									</div>
								</ValidationProvider>

								<!-- Update View -->
								<template v-else>
									<router-link
										:to="{ name: 'CustomerDetail', params: { id: appointmentForm.customerId } }"
										class="link-icon link-redirect"
										>{{ appointmentForm.fullName }}
									</router-link>
								</template>
							</div>
						</div>

						<!-- Customer phone -->
						<div class="form-group required">
							<label for="phone" class="form-group__label">{{ $t("AppointmentForm.Phone") }}</label>
							<div class="form-group__input">
								<!-- Create View -->
								<ValidationProvider
									v-if="!isUpdate"
									v-slot="{ classes, errors }"
									name="appointment_phone"
									rules="required|integer"
								>
									<div :class="[classes]">
										<input
											id="phone"
											v-model.trim="appointmentForm.phoneNumber"
											type="text"
											class="form-control"
											:placeholder="$t('AppointmentForm.EnterPhone')"
											:disabled="isDisablePhone"
											:maxlength="phoneMaxLength"
											@keydown="checkValidPhone($event)"
										/>
										<div class="error-mess">{{ errors[0] }}</div>
									</div>
								</ValidationProvider>

								<!-- Update View -->
								<template v-else>{{ appointmentForm.phoneNumber }}</template>
							</div>
						</div>

						<!-- Customer birthday -->
						<div class="form-group required">
							<label for="birth" class="form-group__label">{{
								$t("AppointmentForm.YearOfBirth")
							}}</label>
							<div class="form-group__input">
								<!-- Create View -->
								<ValidationProvider
									v-if="!isUpdate"
									v-slot="{ classes, errors }"
									name="appointment_birth_year"
									rules="required|integer"
								>
									<div :class="[classes]">
										<input
											id="birth"
											v-model.trim="appointmentForm.birthYear"
											type="text"
											class="form-control"
											:maxlength="maxlengthBirth"
											:disabled="isDisableBirth"
											@keydown="checkValidPhone($event)"
										/>
										<div class="error-mess">{{ errors[0] }}</div>
									</div>
								</ValidationProvider>

								<!-- Update View -->
								<template v-else>{{ appointmentForm.birthYear }}</template>
							</div>
						</div>

						<!-- Customer contact -->
						<div v-if="!isUpdate || appointmentForm.contactInfo" class="form-group mb-4">
							<label for="contact" class="form-group__label">{{
								$t("AppointmentForm.Contact")
							}}</label>
							<div :class="['form-group__input', { contact: !isUpdate }]">
								<!-- Create View -->
								<template v-if="!isUpdate">
									<multiselect
										v-model="appointmentForm.contactTool"
										placeholder=""
										:options="contactMethods"
										:close-on-select="true"
										:show-labels="false"
										:allow-empty="false"
										:searchable="false"
										class="multi-nowrap"
										@close="closeContactMethod"
									>
										<template slot="singleLabel" slot-scope="props"
											><img class="option__image" :src="labelContact(props.option, 'img')" /><span
												class="option__desc"
												><span class="option__title">{{
													labelContact(props.option, "title")
												}}</span></span
											></template
										>
										<template slot="option" slot-scope="props"
											><img class="option__image" :src="labelContact(props.option, 'img')" />
											<div class="option__desc"
												><span class="option__title">{{
													labelContact(props.option, "title")
												}}</span></div
											>
										</template>
									</multiselect>
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="appointment_contact"
										:rules="`${ruleContact.validate}`"
									>
										<div :class="[classes]">
											<input
												id="contact"
												ref="contactInput"
												v-model="appointmentForm.contactInfo"
												type="text"
												class="form-control"
												autocomplete="contact"
												:maxlength="ruleContact.maxLength"
											/>
											<div class="error-mess">{{ errors[0] }}</div>
										</div>
									</ValidationProvider>
								</template>

								<!-- Update View -->
								<template v-else>
									<span class="contact-icon"
										><img
											v-lazy="labelContact(get(appointmentForm, 'contactTool'), 'img')"
											alt
											class="logo"
										/>{{ labelContact(get(appointmentForm, "contactTool"), "title") }}</span
									>
									{{ appointmentForm.contactInfo }}
								</template>
							</div>
						</div>

						<!-- Services -->
						<div class="form-group required service">
							<label for="services" class="form-group__label">{{
								$t("AppointmentForm.Services")
							}}</label>
							<div class="form-group__input">
								<ValidationProvider
									v-slot="{ classes, errors }"
									name="appointment_services"
									rules="required"
								>
									<div :class="[classes]">
										<div class="grid">
											<div v-for="answer in services" :key="answer.id" class="form-check">
												<input
													:id="`service-${answer.id}`"
													v-model="appointmentForm.serviceIds"
													class="form-check-input"
													type="checkbox"
													:value="answer.id"
													:disabled="isCanceledStatus"
													@change="clickServiceCheckbox(answer)"
												/>

												<label class="form-check-label" :for="`service-${answer.id}`">
													{{ answer.name }}
													<div v-if="answer.additionalName">( {{ answer.additionalName }} )</div>
												</label>

												<template v-if="isAdmin && isStatusFinished && isServiceChecked(answer.id)">
													<template v-for="result in answer.resultItems">
														<div v-if="isDislayResult(result)" :key="result.updatedAt">
															<div class="service-form-group">
																<label for="result-common" class="service-form-group__label">{{
																	result.label
																}}</label>
																<div class="service-form-group__input">
																	<ValidationProvider
																		v-slot="{ classes, errors }"
																		:ref="`appointment_result_${result.id}`"
																		:name="`appointment_result_${result.id}`"
																		:rules="{ required: result.required }"
																	>
																		<div :class="[classes]">
																			<!-- Result type: Text box -->
																			<input
																				v-if="isTextbox(result)"
																				v-model.trim="
																					appointmentForm.examinationResult[getKeyResult(result)]
																						.result
																				"
																				type="text"
																				class="form-control"
																			/>

																			<!-- Result type: Select box -->
																			<multiselect
																				v-else
																				v-model.trim="
																					appointmentForm.examinationResult[getKeyResult(result)]
																						.result
																				"
																				:options="result.value"
																				:close-on-select="true"
																				:show-labels="false"
																				:allow-empty="false"
																				:searchable="false"
																				placeholder=""
																				@close="
																					checkValidateSelect(`appointment_result_${result.id}`)
																				"
																				@input="inputExamResult(getKeyResult(result), $event)"
																			>
																				<template #noOptions>{{
																					$t("DropDown.NoOptions")
																				}}</template>
																				<template #noResult>{{ $t("DropDown.NoResult") }}</template>
																			</multiselect>
																			<div class="error-mess">{{ errors[0] }}</div>
																		</div>
																	</ValidationProvider>
																</div>
															</div>
														</div>
													</template>
												</template>
											</div>
										</div>
										<div class="error-mess mt-0">{{ errors[0] }}</div>
									</div>
								</ValidationProvider>
							</div>
						</div>

						<hr class="h-divider mt-0" />

						<!-- Clinics -->
						<div class="form-group clinic">
							<label for="clinics" class="form-group__label"
								>{{ $t("AppointmentForm.Clinics") }}
								<span class="text-danger">*</span>
								<div v-if="imgSelectedClinic && appointmentForm.clinicId" class="mt-3"
									><img v-lazy="imgSelectedClinic" alt class="logo clinic-img"
								/></div>
							</label>
							<div :class="['form-group__input', { 'clinic--height': !isUpdate }]">
								<!-- Admin update or Admin's role is Clinic admin or clinic support -->
								<div v-if="showOneClinic" class="flex-clinic">
									<img v-lazy="get(computedClinic, 'logo')" alt class="logo clinic-img" />
									<div>
										<div class="mb-2">
											<b>{{ get(computedClinic, "name") }}</b>
											<small class="d-block">{{ get(computedClinic, "address") }}</small>
										</div>
										<div>
											<p class="mb-0"
												><span>{{ $t("AppointmentForm.WorkingHours") }}</span
												>: {{ get(computedClinic, "workingHour") }}</p
											>
											<p class="mb-0"
												><span>{{ $t("AppointmentForm.Phone") }}</span
												>: {{ get(computedClinic, "phone") }}</p
											>
											<p class="mb-0"
												><span>{{ $t("AppointmentForm.Website") }}</span
												>:
												<!-- <a :href="computedClinic.website" class="link-redirect">{{
													computedClinic.website
												}}</a> -->
												<router-link
													:to="{
														name: 'UpdateClinic',
														params: { id: get(computedClinic, 'id') },
													}"
													target="_blank"
													class="link-icon link-redirect"
													>{{ get(computedClinic, "website") }}</router-link
												>
											</p>
											<p class="mb-0"
												><span>{{ $t("AppointmentForm.ShortIntro") }}</span
												>: {{ get(computedClinic, "shortIntro") }}
											</p>
										</div>
									</div>
								</div>

								<CTabs v-else variant="pills" :active-tab.sync="activeTab">
									<CTab v-for="(city, index) in mainCities" :key="city.id" :title="city.name">
										<ValidationProvider
											v-if="activeTab === index"
											v-slot="{ classes, errors }"
											name="appointment_clinics"
											rules="required"
										>
											<div :class="[classes]">
												<div v-for="answer in clinics" :key="answer.id" class="form-check">
													<input
														:id="`clinic-${answer.id}`"
														v-model="appointmentForm.clinicId"
														class="form-check-input"
														type="radio"
														name="answersClinic"
														:value="answer.id"
														@input="selectClinic(answer)"
													/>
													<label class="form-check-label clinic__label">
														<label :for="`clinic-${answer.id}`">
															<span class="clinic__name">{{ answer.name }}</span>
															<small class="clinic__address">{{ answer.address }}</small>
														</label>
														<a
															:ref="`accordion-${answer.id}`"
															class="accordion"
															@click="toggleClinic(`accordion-${answer.id}`)"
														></a>
														<div class="panel">
															<p
																><span class="panel__label"
																	>{{ $t("AppointmentForm.WorkingHours") }}: </span
																><span class="panel__des">{{ answer.workingHour }}</span></p
															>
															<p
																><span class="panel__label"
																	>{{ $t("AppointmentForm.Phone") }}: </span
																><span class="panel__des">{{ answer.phone }}</span></p
															>
															<p
																><span class="panel__label"
																	>{{ $t("AppointmentForm.Website") }}: </span
																><a :href="answer.website" target="_blank" class="panel__link">{{
																	answer.website
																}}</a></p
															>
															<p
																><span class="panel__label"
																	>{{ $t("AppointmentForm.ShortIntro") }}: </span
																><span class="panel__des">{{ answer.shortIntro }}</span></p
															>
														</div>
													</label>
												</div>
												<div class="error-mess">{{ errors[0] }}</div>
											</div>
										</ValidationProvider>
									</CTab>
								</CTabs>
							</div>
						</div>

						<!-- Date time -->
						<div class="form-group required">
							<label for="date-time" class="form-group__label">{{
								$t("AppointmentForm.DateTime")
							}}</label>
							<div class="form-group__input">
								<ValidationProvider
									v-slot="{ classes, errors }"
									name="appointment_date"
									rules="required"
								>
									<div :class="[classes]">
										<date-picker
											id="date-time"
											v-model.trim="appointmentForm.planedDate"
											type="datetime"
											value-type="format"
											:format="clientDateFormat"
											:editable="false"
											:show-second="false"
											:show-time-panel="panelPlanedDate"
											:disabled="isCanceledStatus"
											class="w-100"
											@close="handleOpenChange('panelPlanedDate')"
											><template #footer>
												<button
													class="mx-btn mx-btn-text"
													@click="toggleTimePanel('panelPlanedDate')"
												>
													{{
														panelPlanedDate
															? $t("DatePicker.SelectDate")
															: $t("DatePicker.SelectTime")
													}}
												</button>
											</template>
											<template v-if="!isAdmin" #icon-calendar
												><img v-lazy="calendar" alt class="logo" /></template
										></date-picker>
										<div class="error-mess">{{ errors[0] }}</div>
									</div>
								</ValidationProvider>
							</div>
						</div>

						<!-- HIV result -->
						<!-- Remove this -->
						<!-- <div class="form-group hiv-result">
							<label for="hiv-result" class="form-group__label">{{
								$t("AppointmentForm.HIVResult")
							}}</label>
							<div class="form-group__input">
								<ValidationProvider
									v-if="!isUpdate"
									v-slot="{ classes, errors }"
									ref="hiv_result"
									name="appointment_hiv_result"
								>
									<div :class="[classes]">
										<multiselect
											id="hiv-result"
											v-model.trim="appointmentForm.hivTestResult"
											:custom-label="labelHIV"
											:options="hivResults"
											:close-on-select="true"
											:show-labels="false"
											:allow-empty="true"
											:searchable="true"
											placeholder=""
											@close="checkValidate('hiv_result')"
										>
											<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
											<template #noResult>{{ $t("DropDown.NoResult") }}</template>
										</multiselect>
										<div class="error-mess">{{ errors[0] }}</div>
									</div>
								</ValidationProvider>

								<template v-else>{{ labelHIV(get(appointmentForm, "hivTestResult")) }}</template>
							</div>
						</div> -->

						<!-- Note -->
						<div class="form-group">
							<label for="note" class="form-group__label">{{ $t("AppointmentForm.Note") }}</label>
							<div class="form-group__input">
								<ValidationProvider v-slot="{ classes, errors }" name="appointment_note">
									<div :class="[classes]">
										<textarea
											id="note"
											v-model.trim="appointmentForm.note"
											class="form-control"
											rows="3"
											:disabled="isCanceledStatus"
										></textarea>
										<div class="error-mess">{{ errors[0] }}</div>
									</div>
								</ValidationProvider>
							</div>
						</div>

						<!-- Customer View - SMS -->
						<div v-if="!isAdmin" class="form-group">
							<label for="received-sms" class="form-group__label">
								<span class="label-sms">{{ $t("AppointmentForm.ReceivedSMS") }}</span>
							</label>
							<div class="form-group__input">
								<ValidationProvider v-slot="{ classes, errors }" name="appointment_sms">
									<div :class="[classes]">
										<div class="group-sms">
											<div
												v-for="option in optionSMS"
												:key="option"
												class="form-check group-sms__item"
											>
												<input
													:id="option"
													v-model="configSMS"
													:value="option"
													:disabled="isDisableItemSMS(option)"
													class="form-check-input"
													type="checkbox"
												/>
												<label class="form-check-label" :for="option">{{ labelSMS(option) }}</label>
											</div>
										</div>

										<span class="note-sms">{{ $t("AppointmentForm.NoteSMS") }}</span>
										<div class="error-mess">{{ errors[0] }}</div>
									</div>
								</ValidationProvider>
							</div>
						</div>
					</div>

					<hr v-if="!isAdmin" class="h-divider" />

					<div v-if="isInvalidForm" class="text-center text-danger mb-2">{{
						$t("Form.Error")
					}}</div>
					<div v-if="isAdmin" class="appt-form__action text-center">
						<CButton
							v-if="!isCanceledStatus"
							v-bind="{ variant: 'outline' }"
							color="info"
							type="submit"
							>{{ textSubmit }}</CButton
						>
						<CButton
							v-if="isShowDelBtn && !isCanceledStatus"
							v-bind="{ variant: 'outline' }"
							color="warning"
							@click="toggleDeleteModal(true)"
							>{{ $t("Button.Delete") }}</CButton
						>
						<CButton v-bind="{ variant: 'outline' }" color="danger" @click="cancelForm">{{
							$t("Button.Exit")
						}}</CButton>
					</div>
					<div v-else class="text-right">
						<CButton color="info" type="submit" class="btn-finish">{{
							$t("AppointmentForm.Finish")
						}}</CButton>
					</div>
				</form>
			</ValidationObserver>
		</div>

		<!-- Show modal when delete form -->
		<CustomModal
			v-if="isShowDelModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="deleteApptById"
			@cancel-modal="toggleDeleteModal(false)"
		>
			<template #content>
				<p>{{ $t("AppointmentForm.AreYouSure") }}</p>
				<span>{{ $t("AppointmentForm.DeleteDescription") }}</span>
			</template>
		</CustomModal>

		<!-- Show modal when cancel if adminForm was changed -->
		<CustomModal
			v-if="isShowDiscardModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="discardChanges"
			@cancel-modal="toggleDiscardModal(false)"
		>
			<template #content>
				<p>{{ $t("Button.DiscardChanges") }}</p>
				<span>{{ $t("Button.DiscardContent") }}</span>
			</template>
		</CustomModal>
	</section>
</template>

<script>
import {
	CONTACT_METHOD,
	// HIV_RESULTS, Remove this
	APPT_STATUS,
	INPUT_TYPE,
	INPUT_LENGTH,
	USER_ROLE,
	CONFIG_SMS,
} from "@/shared/plugins/constants"
import { GET_SERVICES, GET_MAIN_CITIES, GET_CLINICS, GET_CUSTOMERS } from "./../store/action-types"
import {
	RESET_CUSTOMERS,
	CHANGE_STATUS_DISCARD_MODAL,
	RESET_CLINICS,
	RESET_APPT_FORM,
	CHANGE_STATUS_DELETE_MODAL,
} from "./../store/mutation-types"
import phone from "@/assets/img/phone-call.svg"
import messenger from "@/assets/img/messenger.svg"
import zalo from "@/assets/img/zalo.svg"
import viber from "@/assets/img/viber.svg"
import skype from "@/assets/img/skype.svg"
import whatsapp from "@/assets/img/whatsapp.svg"
import email from "@/assets/img/gmail.svg"
import { createNamespacedHelpers, mapState } from "vuex"
const { mapActions, mapMutations } = createNamespacedHelpers("serviceAppointment")
import moment from "moment"
import calendar from "@/assets/img/calendar.svg"
import { get, isEqual, debounce, findIndex } from "lodash-es"

export default {
	name: "AppointmentForm",

	components: {
		InfiniteLoading: () => import("vue-infinite-loading"),
	},

	props: {
		isAdmin: {
			type: Boolean,
			default: false,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
		fromCustomer: {
			type: [Boolean, String],
			default: false,
		},
	},

	data() {
		return {
			calendar,
			linkMaxLength: INPUT_LENGTH.WEBSITE,
			phoneMaxLength: process.env.VUE_APP_PHONE_MAX_LENGTH,
			defaultMaxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			clientDateFormat: process.env.VUE_APP_CLIENT_DATE_FORMAT,
			serverDateFormat: process.env.VUE_APP_SERVER_DATE_FORMAT,
			maxlengthBirth: process.env.VUE_APP_BIRTH_YEAR,
			limitDropDown: Number(process.env.VUE_APP_LIMIT_LIST),
			isCloseName: null,
			activeTab: 0,
			findCustomer: null,
			infiniteCustomerId: Number(new Date()),
			requiredResultDate: false,
			requiredNextSchedule: false,
			requiredPlannedDate: false,
			panelFinishedDate: false,
			panelExamDate: false,
			panelPlanedDate: false,
			idResult: 0,
			isMobile: false,
			isInvalidForm: false,
			computedServices: [],
			imgSelectedClinic: null,

			originForm: {
				status: null,
				fullName: null,
				phoneNumber: null,
				birthYear: null,
				contactTool: CONTACT_METHOD.EMAIL,
				contactInfo: null,
				planedDate: null,
				note: null,
				clinicId: null,
				serviceIds: [],
				// hivTestResult: null, Remove this
				// Fields of admin side
				customerId: null,
				finishedDate: null,
				nextExamDate: null,
				examinationResult: {},

				// Fields of customer side
				smsRemind: false,
				smsFeedback: false,
			},

			appointmentForm: {},

			status: [APPT_STATUS.PENDING, APPT_STATUS.COMPLETE, APPT_STATUS.CUSTOMER_CANCEL],

			contactMethods: [
				CONTACT_METHOD.PHONE,
				CONTACT_METHOD.MESSENGER,
				CONTACT_METHOD.ZALO,
				CONTACT_METHOD.VIBER,
				CONTACT_METHOD.SKYPE,
				CONTACT_METHOD.WHATSAPP,
				CONTACT_METHOD.EMAIL,
			],

			configSMS: [],
			optionSMS: [CONFIG_SMS.REMENDER, CONFIG_SMS.FEEDBACK, CONFIG_SMS.NONE],

			// Remove this
			// hivResults: [HIV_RESULTS.POSITIVE, HIV_RESULTS.NEGATIVE, HIV_RESULTS.NOT_AVAILABLE],

			isStopTreatPrEP: false,
			stopTreatmentPrEP: [
				"Dừng sử dụng PrEP do nhiễm mới HIV",
				"Dừng sử dụng PrEP do độc tính của thuốc",
				"Dừng sử dụng PrEP do khách hàng không còn nguy cơ",
				"Dừng do nguyên nhân khác",
				"Mất dấu khách hàng",
				"Dừng do tử vong",
			],
		}
	},

	computed: {
		...mapState("serviceAppointment", [
			"recentAppt",
			"services",
			"mainCities",
			"clinics",
			"customers",
			"totalCustomer",
			"filtersCustomer",
			"isShowDiscardModal",
			"isShowDelModal",
		]),
		...mapState("customers", ["recentCustomer"]),
		...mapState("authentication", ["currentUser"]),

		combinedServiceCity: function () {
			return this.activeTab + this.appointmentForm.serviceIds + this.mainCities
		},

		computedStatus: function () {
			return this.labelStatus(this.appointmentForm.status)
		},

		computedClinic: function () {
			// System admin update
			// Clinic admin, clinic support create or update
			if (this.isUpdate) return this.appointmentForm?.clinicId
			else if (!this.isSystemAdmin) return this.clinics[0]

			return {}
		},

		ruleContact: function () {
			switch (this.appointmentForm.contactTool) {
				case CONTACT_METHOD.PHONE:
				case CONTACT_METHOD.ZALO:
				case CONTACT_METHOD.VIBER:
					return {
						validate: "integer",
						maxLength: this.phoneMaxLength,
					}

				case CONTACT_METHOD.MESSENGER:
				case CONTACT_METHOD.SKYPE:
				case CONTACT_METHOD.WHATSAPP:
					return {
						validate: "",
						maxLength: this.linkMaxLength,
					}

				case CONTACT_METHOD.EMAIL:
					return {
						validate: "email|isEmail",
						maxLength: this.defaultMaxLength,
					}

				default:
					return {
						validate: "required",
						maxLength: this.defaultMaxLength,
					}
			}
		},

		isStatusFinished: function () {
			return this.appointmentForm.status === APPT_STATUS.COMPLETE
		},

		isDisableName: function () {
			return this.findCustomer && this.appointmentForm.fullName
		},

		isDisableBirth: function () {
			return (this.findCustomer && this.appointmentForm.birthYear) || this.isMobile
		},

		isDisablePhone: function () {
			return (this.findCustomer && this.appointmentForm.phoneNumber) || this.isMobile
		},

		isCanceledStatus: function () {
			return this.recentAppt?.status === APPT_STATUS.CUSTOMER_CANCEL
		},

		isSystemAdmin: function () {
			return this.currentUser?.role === USER_ROLE.SYSTEM_ADMIN
		},

		isShowDelBtn: function () {
			return this.isUpdate && this.recentAppt?.status !== APPT_STATUS.COMPLETE
		},

		showOneClinic: function () {
			if (!this.isAdmin) return false

			return this.isUpdate || !this.isSystemAdmin
		},

		textSubmit: function () {
			return this.isUpdate ? this.$t("Button.SaveChanges") : this.$t("Button.CreateNew")
		},
	},

	watch: {
		recentAppt: function () {
			this.setUpData()
		},

		findCustomer: function (val) {
			this.appointmentForm.customerId = val?.id
			this.appointmentForm.fullName = val?.fullName
			this.appointmentForm.phoneNumber = val?.phone
			this.appointmentForm.birthYear = val?.birthYear
			this.appointmentForm.contactInfo = val?.contactInfo

			if (val && val.contactTool) this.appointmentForm.contactTool = val.contactTool
		},

		combinedServiceCity: async function () {
			if (this.mainCities[this.activeTab]?.id && !this.isUpdate) {
				const params =
					!this.isAdmin || this.isSystemAdmin
						? {
								serviceIds: this.appointmentForm.serviceIds?.toString(),
								cityId: this.mainCities[this.activeTab]?.id,
						  }
						: {}
				this.GET_CLINICS(params)
			}
		},

		computedClinic: function (val) {
			if (this.isAdmin && (this.isUpdate || !this.isSystemAdmin))
				this.GET_SERVICES({ clinicId: val?.id })
		},

		"appointmentForm.contactTool": function () {
			if (!this.isUpdate) this.appointmentForm.contactInfo = null
		},

		clinics: function (val) {
			if (findIndex(val, { id: this.appointmentForm.clinicId }) < 0)
				this.appointmentForm.clinicId = null

			if (this.isAdmin && !this.isSystemAdmin) this.appointmentForm.clinicId = val[0].id
		},
	},

	created() {
		if (!this.isUpdate) {
			this.GET_MAIN_CITIES()
			this.setUpData()

			if (this.isSystemAdmin || !this.isAdmin) this.GET_SERVICES()
		}
	},

	mounted() {
		// Get data from iframe
		window.addEventListener("message", this.setDataFromIframe, false)
	},

	beforeDestroy() {
		window.removeEventListener("message", this.setDataFromIframe)

		this.RESET_APPT_FORM()
	},

	methods: {
		get,

		...mapActions({ GET_SERVICES, GET_MAIN_CITIES, GET_CLINICS, GET_CUSTOMERS }),
		...mapMutations({
			RESET_CUSTOMERS,
			CHANGE_STATUS_DISCARD_MODAL,
			RESET_CLINICS,
			RESET_APPT_FORM,
			CHANGE_STATUS_DELETE_MODAL,
		}),

		setUpData() {
			if (this.isUpdate) {
				const recentData = { ...this.recentAppt }

				this.appointmentForm = {
					id: recentData.id,
					code: recentData.code,
					status: recentData.status,
					fullName: recentData.customer?.fullName,
					phoneNumber: recentData.customer?.phone,
					birthYear: recentData.customer?.birthYear,
					contactTool: recentData.contactTool,
					contactInfo: recentData.contactInfo,
					serviceIds: recentData.requestedServices?.map(service => service.id),
					clinicId: recentData.clinic,
					planedDate: moment(recentData.planedDate).format(this.clientDateFormat),
					// hivTestResult: recentData.hivTestResult, Remove this
					note: recentData.note,
				}

				if (this.isAdmin) {
					this.appointmentForm.customerId = recentData.customer.id
					this.appointmentForm.finishedDate = this.getTimeLocale(
						recentData.finishedDate,
						this.clientDateFormat,
					)
					this.appointmentForm.nextExamDate = this.getTimeLocale(
						recentData.nextExamDate,
						this.clientDateFormat,
					)

					const objOther = {}
					if (recentData.status === APPT_STATUS.COMPLETE) {
						for (const item of recentData.examinationResult) {
							objOther[this.getKeyResult(item)] = item

							this.inputExamResult(item.code, item.result)
							if (this.isStopTreatPrEP) {
								objOther["prepRegimen"] = {
									serviceId: item.serviceId,
									code: "prepRegimen",
									result: null,
								}
								objOther["medicineNumber"] = {
									serviceId: item.serviceId,
									code: "medicineNumber",
									result: null,
								}
							}
						}
					}
					this.appointmentForm.examinationResult = { ...objOther }
				}
			} else {
				this.appointmentForm = { ...this.originForm }

				if (!this.isAdmin) {
					// Fill user info from mobile device
					const dataIframe = JSON.parse(localStorage.getItem("dataIframe"))
					if (dataIframe) {
						this.appointmentForm.fullName = dataIframe.fullName
						this.appointmentForm.phoneNumber = dataIframe.phoneNumber
						this.appointmentForm.birthYear = dataIframe.birthYear
						this.appointmentForm.serviceIds = dataIframe.serviceIds?.length
							? dataIframe.serviceIds
							: []

						// this.isMobile = true
					} else {
						// In contrast
						const info = JSON.parse(localStorage.getItem("riskInfo"))
						this.appointmentForm.phoneNumber = info?.phoneNumber
						this.appointmentForm.birthYear = info?.birthYear
					}
				} else if (this.fromCustomer) {
					// Set info of user when come from user detail
					this.appointmentForm.fullName = this.recentCustomer?.fullName
					this.appointmentForm.phoneNumber = this.recentCustomer?.phone
					this.appointmentForm.birthYear = this.recentCustomer?.birthYear
					this.appointmentForm.contactTool =
						this.recentCustomer?.contactTool || CONTACT_METHOD.EMAIL
					this.appointmentForm.contactInfo = this.recentCustomer?.contactInfo
				}
			}

			this.setUpSMS()
		},

		applyForm: async function () {
			const valid = await this.$refs.form.validate()
			if (!valid) {
				this.isInvalidForm = true
				return
			}
			this.isInvalidForm = false

			const dataParams = {
				...this.appointmentForm,
				serviceIds: this.appointmentForm.serviceIds.toString(),
				planedDate: moment(this.appointmentForm.planedDate, this.clientDateFormat).format(
					this.serverDateFormat,
				),
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC",
				isCompletedRisk: false,
			}

			let isCompletedRisk = localStorage.getItem("isCompletedRisk")
			if (isCompletedRisk === "true" || isCompletedRisk === true) dataParams.isCompletedRisk = true

			if (this.isAdmin) {
				if (this.isStopTreatPrEP) {
					delete dataParams.examinationResult.prepRegimen
					delete dataParams.examinationResult.medicineNumber
				}

				dataParams.examinationResult = Object.values(dataParams.examinationResult)
				dataParams.finishedDate = this.formatDateServer(this.appointmentForm.finishedDate)
				dataParams.nextExamDate = this.formatDateServer(this.appointmentForm.nextExamDate)

				if (this.isUpdate) dataParams.clinicId = dataParams.clinicId.id
			} else {
				dataParams.smsRemind = this.configSMS.includes(CONFIG_SMS.REMENDER)
				dataParams.smsFeedback = this.configSMS.includes(CONFIG_SMS.FEEDBACK)
			}

			this.$emit("submit-form", dataParams)
		},

		cancelForm() {
			if (this.isAdmin) {
				let isEq
				if (this.isUpdate) {
					const isEqualAll = {}
					isEqualAll["status"] = this.appointmentForm.status === this.recentAppt.status
					const services = this.recentAppt.requestedServices?.map(e => e.id)
					isEqualAll["services"] = isEqual(this.appointmentForm.serviceIds, services)
					isEqualAll["planedDate"] =
						this.appointmentForm.planedDate ===
						moment(this.recentAppt.planedDate).format(this.clientDateFormat)
					isEqualAll["note"] = this.appointmentForm.note === this.recentAppt.note
					isEqualAll["otherExamResults"] = isEqual(
						Object.values(this.appointmentForm.examinationResult),
						this.recentAppt.examinationResult,
					)

					isEq = Object.values(isEqualAll).indexOf(false) < 0
				} else {
					isEq = isEqual(this.appointmentForm, this.originForm)
				}

				if (!isEq) this.toggleDiscardModal(true)
				else this.$emit("cancel-form")
			}
		},

		setUpSMS() {
			if (this.isUpdate) {
				if (!this.appointmentForm.smsRemind && !this.appointmentForm.smsFeedback)
					this.configSMS = [CONFIG_SMS.NONE]
				else {
					this.appointmentForm.smsRemind && this.configSMS.push(CONFIG_SMS.REMENDER)
					this.appointmentForm.smsFeedback && this.configSMS.push(CONFIG_SMS.FEEDBACK)
				}
			} else {
				this.configSMS = [CONFIG_SMS.REMENDER, CONFIG_SMS.FEEDBACK]
			}
		},

		getCustomers(keyword) {
			this.RESET_CUSTOMERS()
			this.GET_CUSTOMERS({ ...this.filtersCustomer, keyword: keyword })
		},

		asyncFindCustomers: debounce(async function (query) {
			await this.RESET_CUSTOMERS()
			await this.GET_CUSTOMERS({
				...this.filtersCustomer,
				keyword: query.trim(),
			})

			if (this.customers.length >= this.filtersCustomer.limit) this.infiniteCustomerId += 1
		}, process.env.VUE_APP_DEBOUNCE_TIME),

		async infiniteHandlerCustomer($state) {
			this.filtersCustomer.offset = this.customers.length

			const { items } = await this.GET_CUSTOMERS(this.filtersCustomer)
			if (!items.length) {
				$state.complete()
			}
			$state.loaded()
		},

		resetCustomers() {
			this.RESET_CUSTOMERS()
		},

		clearFindCustomer() {
			this.findCustomer = null
		},

		isAppendLoading(array) {
			return array.length >= this.limitDropDown
		},

		nameWithPhone({ fullName, phone }) {
			return `${fullName} - ${phone}`
		},

		closeContactMethod() {
			this.$refs.contactInput.focus()
		},

		discardChanges() {
			this.toggleDiscardModal(false)
			this.$emit("cancel-form")
		},

		toggleDiscardModal(status = false) {
			this.CHANGE_STATUS_DISCARD_MODAL(status)
		},

		deleteApptById() {
			this.$emit("delete-form", { id: this.appointmentForm.id })
			this.toggleDeleteModal(false)
		},

		toggleDeleteModal(status = false) {
			this.CHANGE_STATUS_DELETE_MODAL(status)
		},

		labelStatus(value) {
			return {
				[APPT_STATUS.PENDING]: this.$t("AppointmentDetail.Pending"),
				[APPT_STATUS.CUSTOMER_CANCEL]: this.$t("AppointmentDetail.Cancel"),
				[APPT_STATUS.COMPLETE]: this.$t("AppointmentDetail.Complete"),
			}[value]
		},

		labelContact(value, key) {
			const contact = {
				[CONTACT_METHOD.PHONE]: { title: "Phone", img: phone },
				[CONTACT_METHOD.MESSENGER]: { title: "Messenger", img: messenger },
				[CONTACT_METHOD.ZALO]: { title: "Zalo", img: zalo },
				[CONTACT_METHOD.VIBER]: { title: "Viber", img: viber },
				[CONTACT_METHOD.SKYPE]: { title: "Skype", img: skype },
				[CONTACT_METHOD.WHATSAPP]: { title: "Whatsapp", img: whatsapp },
				[CONTACT_METHOD.EMAIL]: { title: "Email", img: email },
			}[value]

			if (contact) return contact[key]
			return null
		},

		// Remove this
		// labelHIV(value) {
		// 	return {
		// 		[HIV_RESULTS.NEGATIVE]: this.$t("ApptHIVResult.Negative"),
		// 		[HIV_RESULTS.POSITIVE]: this.$t("ApptHIVResult.Positive"),
		// 		[HIV_RESULTS.NOT_AVAILABLE]: this.$t("ApptHIVResult.NotAvailable"),
		// 	}[value]
		// },

		checkValidate(field) {
			if (this.$refs[field]) this.$refs[field].validate()
		},

		checkValidateSelect(field) {
			if (this.$refs[field]) this.$refs[field][0].validate()
		},

		toggleClinic(ref) {
			const accordion = this.$refs[ref][0]
			accordion.classList.toggle("accordion--active")
			var panel = accordion.nextElementSibling
			if (panel.style.maxHeight) {
				panel.style.maxHeight = null
			} else {
				panel.style.maxHeight = panel.scrollHeight + "px"
			}
		},

		isServiceChecked(serviceId) {
			return this.appointmentForm.serviceIds?.includes(serviceId)
		},

		inputExamResult(field, value) {
			if (field === "treatmentStatus") this.isStopTreatPrEP = this.stopTreatmentPrEP.includes(value)
		},

		isDislayResult(result) {
			const field = this.getKeyResult(result)
			if (this.isStopTreatPrEP && ["prepRegimen", "medicineNumber"].includes(field)) return false
			return true
		},

		clickServiceCheckbox(service) {
			if (!this.isAdmin) return null

			for (const result of service.resultItems) {
				const key = this.getKeyResult(result)
				if (!Object.keys(this.appointmentForm.examinationResult).includes(key.toString())) {
					this.appointmentForm.examinationResult[key] = {
						serviceId: service.id,
						code: result.code,
						result: result.defaultValue,
					}
				} else {
					delete this.appointmentForm.examinationResult[key]
				}
			}
		},

		getKeyResult(result) {
			if (result.code === "general") return `${result.code}${result.serviceId}`
			return result.code
		},

		formatDateServer(date) {
			const dateServer = moment(date, this.clientDateFormat)
			if (dateServer.isValid()) return dateServer.format(this.serverDateFormat)

			return null
		},

		setDataFromIframe(event) {
			if (event.data && event.data.idName === "pass-params") {
				if (!this.isAdmin) {
					// Fill user info from mobile device
					const dataIframe = {}
					dataIframe.fullName = event.data.params.fullname
					dataIframe.phoneNumber = event.data.params.phone
					dataIframe.birthYear = event.data.params.birth
					if (event.data.params.serviceIds && event.data.params.serviceIds.length)
						dataIframe.serviceIds = event.data.params.serviceIds.split(",")

					this.appointmentForm = { ...this.appointmentForm, ...dataIframe }
					localStorage.setItem("dataIframe", JSON.stringify(dataIframe))
					this.isMobile = true
					window.removeEventListener("message", this.setDataFromIframe)
				}
			}
		},

		setDataExamResult() {
			if (
				this.services.length &&
				this.isUpdate &&
				this.isAdmin &&
				!Object.keys(this.appointmentForm.examinationResult).length
			) {
				const objOther = {}
				for (const service of this.services) {
					if (this.appointmentForm.serviceIds?.includes(service.id)) {
						for (const result of service.resultItems) {
							objOther[this.getKeyResult(result)] = { ...result }
							objOther[this.getKeyResult(result)].result = result.defaultValue
						}
					}
				}
				this.appointmentForm.examinationResult = { ...objOther }
			}
		},

		selectStatus(status) {
			if (status === APPT_STATUS.COMPLETE) {
				this.setDataExamResult()

				if (!this.appointmentForm?.finishedDate && !this.recentAppt?.finishedDate)
					this.appointmentForm.finishedDate = moment().format("DD/MM/YYYY - HH:mm")
			}
		},

		selectClinic(clinic) {
			if (this.isUpdate || (this.isAdmin && !this.isSystemAdmin)) return

			this.imgSelectedClinic = clinic.logo
		},

		labelSMS(value) {
			return {
				[CONFIG_SMS.REMENDER]: this.$t("AppointmentForm.ReminderAppt"),
				[CONFIG_SMS.FEEDBACK]: this.$t("AppointmentForm.ServiceRatingPrep"),
				[CONFIG_SMS.NONE]: this.$t("AppointmentForm.DontNeed"),
			}[value]
		},

		isDisableItemSMS(option) {
			if (!this.configSMS.length) return false

			const sms = [...this.configSMS]
			// Or select None
			// Or select REMENDER and FEEDBACK
			if (
				(option === CONFIG_SMS.NONE && !sms.includes(CONFIG_SMS.NONE)) ||
				(option !== CONFIG_SMS.NONE && sms.includes(CONFIG_SMS.NONE))
			)
				return true
		},

		toggleTimePanel(panel) {
			this[panel] = !this[panel]
		},

		handleOpenChange(panel) {
			this[panel] = false
		},

		isTextbox(result) {
			return result.type === INPUT_TYPE.TEXT
		},

		notBeforeNow(date) {
			if (date.setMinutes(0, 0, 0) < new Date().setMinutes(0, 0, 0)) return true
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.flex-between {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 0 20px;
}

.form-group,
.sub-form-group {
	display: flex;
	flex-wrap: wrap;
	gap: 2%;

	&__label {
		flex: 0 0 100%;
		max-width: 100%;
		font-size: 16px;
		font-weight: 700;
		color: $color-black-pearl;

		@media (min-width: $xxs) {
			flex: 0 0 24%;
			max-width: 24%;
		}
	}

	&__input {
		flex: 0 0 100%;
		max-width: 100%;

		@media (min-width: $xxs) {
			flex: 0 0 74%;
			max-width: 74%;
		}
	}

	.contact {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: min-content 1fr;
		border: 1px solid $color-hawkes-blue;
		border-radius: 0.25rem;
		height: 38px;
		position: relative;

		&:focus-within {
			@include input-focus;
		}

		/deep/ .multiselect {
			min-height: 32px;
			height: 32px;
			padding: 3px;
			position: relative;
			top: -1px;

			&__select {
				height: 32px;
				min-height: 32px;
				top: 3px;
			}

			&__tags {
				width: max-content;
				border: none;
				padding-top: 5px;
				height: 32px;
				min-height: 32px;
				border-radius: 100px;
				background: $color-black-90;
			}

			&__single {
				background: rgba(38, 34, 48, 0);
			}

			&__content-wrapper {
				width: auto;
			}

			.option__desc {
				position: relative;
				top: 1px;
			}
		}

		input {
			// padding-left: 110px;
			border: none;
			box-shadow: none;
			height: 36px;
		}

		.is-invalid {
			.form-control {
				border: 1px solid;
			}
		}

		.error-mess {
			position: absolute;
			left: 0;
		}
	}

	.contact-icon {
		display: inline-block;
		padding: 2px 8px;
		background: rgba(38, 34, 48, 0.05);
		border-radius: 100px;
		margin-right: 10px;

		img {
			width: 20px;
			height: auto;
			position: relative;
			top: -2px;
			margin-right: 10px;
		}
	}

	.grid {
		@media (min-width: $xs) {
			grid-template-columns: 1fr 1fr;
		}
	}

	/deep/ .nav-link {
		background: $color-alice-blue;
		color: $color-siam;
		padding: 6px 18px;
		margin-bottom: 5px;
	}
	/deep/ .nav-link.active {
		background: $color-pink;
		color: $color-white;
	}

	/deep/ .mx-input {
		height: 38px;
		font-size: 16px;
		color: $color-dark-blue;
		box-shadow: none;
	}
}

.sub-form-group {
	&__label {
		font-size: 13px;
	}
}

.form-group.required,
.sub-form-group.required {
	& > label::after {
		content: "*";
		color: $color-red;
		margin-left: 2px;
	}
}

.clinic {
	margin-bottom: 30px;

	/deep/ .tab-content {
		@include scrollbar;
		max-height: 340px;
		overflow: auto;
		margin: 10px 0;
	}
	&__label {
		p {
			margin-bottom: 0px;
		}
	}
	&__name {
		color: $color-black;
		font-weight: bold;
		display: block;
		width: 90%;
		word-break: break-word;
	}
	&__address {
		display: block;
		font-size: 14px;
		color: $color-black-pearl;
		word-break: break-all;
	}

	/deep/ .nav {
		gap: 10px;
	}

	.form-group__input {
		position: relative;

		.error-mess {
			position: absolute;
			bottom: -14px;
		}
	}

	.form-check + .form-check::before {
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		background: $color-gray90;
		margin-bottom: 4px;
		position: relative;
		left: -21px;
	}
}

/deep/ .nav-link:hover {
	color: $color-siam !important;
}
/deep/ .nav-link.active:hover {
	color: $color-white !important;
}

.grid {
	@include grid-between;
	grid-template-columns: 1fr;
	gap: 5px;

	.error-mess {
		grid-column: 1 / -1;
	}
}

input,
textarea {
	font-size: 16px;
	color: #35495e;

	&:focus {
		@include input-focus;
	}
}

/deep/ .multiselect {
	min-height: 35px;

	&__content-wrapper {
		@include scrollbar;
	}

	&:hover {
		cursor: pointer;
	}

	&__single,
	&__option {
		display: flex;
		gap: 10px;
	}
	&__option {
		white-space: normal;
		word-break: break-word;
	}
	&__tags {
		padding-top: 8px;
		min-height: 38px;
		border: 1px solid $color-hawkes-blue;
	}
	&__select {
		height: 38px;
	}

	&__input:focus {
		border: none;
		box-shadow: none;
	}

	&__placeholder {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.option {
		&__image {
			width: 20px;
			height: 20px;
			object-fit: cover;
		}

		&__desc {
			align-self: center;
		}
	}
}

.form-check-input:focus {
	box-shadow: none;
}

.accordion {
	position: absolute;
	right: 0;

	&:hover {
		cursor: pointer;
	}

	&::after {
		background: transparent url("./../../../assets/img/arrow-down-right.svg") no-repeat;
		transform: rotate(90deg);
		transform-origin: 37% 37%;
	}
}

.accordion::after,
.accordion--active::after {
	content: "";
	display: block;
	width: 27px;
	height: 27px;
}

.accordion--active::after {
	background: transparent url("./../../../assets/img/arrow-down-right.svg") no-repeat;
	transform: rotate(0deg);
}

.panel {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
	word-break: break-all;

	&__label {
		color: $color-siam;
	}

	&__des {
		color: $color-black-pearl;
	}

	&__link {
		color: $color-primary;
		word-break: break-all;
	}
}

.clinic__address,
.panel {
	position: relative;
	left: -20px;
}

.service-form-group {
	display: flex;
	flex-wrap: wrap;
	gap: 1%;

	&__label {
		font-size: 13px;
		color: inherit;
		font-weight: 500;
		flex: 0 0 100%;
		max-width: 100%;

		@media (min-width: $xs) {
			flex: 0 0 30%;
			max-width: 30%;
		}
	}

	&__input {
		flex: 0 0 100%;
		max-width: 100%;
		@media (min-width: $xs) {
			flex: 0 0 69%;
			max-width: 69%;
		}
	}
}

.flex-clinic {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;

	@media (min-width: $xxs) {
		flex-wrap: nowrap;
	}
}

.clinic-img {
	width: 100%;
	height: 120px;
	-o-object-fit: contain;
	object-fit: contain;
	max-width: 150px;
}

.group-sms {
	display: flex;
	flex-wrap: wrap;

	&__item {
		margin-right: 18px;
		&:not(:last-child) {
			margin-bottom: 4px;
		}

		@media (min-width: $xxs) {
			margin-bottom: 0 !important;
		}
	}
}

.note-sms {
	font-size: 12px;
	opacity: 0.9;
}

.label-sms {
	display: block;

	@media (min-width: $m) {
		width: 85%;
	}
	@media (min-width: $l) {
		width: 70%;
	}
	@media (min-width: $xxl) {
		width: 50%;
	}
}
</style>
